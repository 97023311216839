import { useEffect, useRef, useState } from "react";
import "./styles.css";

import React from "react";
import { useNotifications } from "../../utils/NotificationContext";
import CustomButton from "../Buttons";
import { getCookie, setCookie } from "../../utils/cookie";
import { isLoggedIn } from "../../utils/API/auth";

const Notifications = () => {
  const { notifications, addNotification } = useNotifications();

  const [notificationRequestHandled, setNotificationRequestHandled] =
    useState(false);

  const is_request_notification =
    getCookie("attempt_request_notification") === "true" &&
    getCookie("notifications_blocked") !== "true" &&
    isLoggedIn();
  function handleNotificationRequestClick() {
    setCookie("attempt_request_notification", "false", 1);

    if (!("Notification" in window)) {
      setNotificationRequestHandled(true);
      return;
    }

    if (Notification.permission === "granted") {
      setNotificationRequestHandled(true);
      return;
    }

    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        addNotification("Thank you for allowing notifications.", 10);
        setNotificationRequestHandled(true);
      }

      if (permission === "denied") {
        addNotification("You have blocked notifications.", 10);
        setCookie("notifications_blocked", "true", 7);
        setNotificationRequestHandled(true);
      }
    });
  }

  return (
    <>
      <div className={`absolute notification-wrapper no-bg`}>
        {notifications
          .map((notif, index) => (
            <FloatingNotification
              initial_lifetime={notif.timeStart}
              notification_id={notif.id}
              key={notif.id}
            >
              {notif.text}
            </FloatingNotification>
          ))
          .reverse()}
      </div>

      {is_request_notification && !notificationRequestHandled && (
        <PersistentNotification
          type="request_notification"
          onClick={handleNotificationRequestClick}
        ></PersistentNotification>
      )}
    </>
  );
};

const FloatingNotification = ({
  initial_lifetime,
  notification_id,
  children,
}) => {
  const [timeLeft, setTimeLeft] = useState(initial_lifetime);

  const { removeNotification } = useNotifications();

  const notificationLifetimeInterval = useRef(null);
  useEffect(() => {
    notificationLifetimeInterval.current = setInterval(() => {
      setTimeLeft((t) => t - 0.05);
    }, 50);

    return () => {
      clearInterval(notificationLifetimeInterval.current);
    };
  }, []);

  useEffect(() => {
    if (timeLeft < -1) {
      removeNotification(notification_id);
    }
  }, [removeNotification, timeLeft, notification_id]);

  if (timeLeft < 0 || children === undefined) return <></>;
  return (
    <div className={`z-top-3 ${timeLeft <= 1 ? "disappearing" : ""}`}>
      {children}
      <div
        className="absolute z-top-3"
        style={{
          right:
            "calc(" + (100 - timeLeft * (100 / initial_lifetime)) + "% + 13px)",
        }}
      />

      <div className="dismiss-button no-bg">
        <img
          src="/assets/images/clear_small.png"
          alt="Dismiss button"
          onClick={() => {
            if (timeLeft > 1) setTimeLeft(1);
          }}
        />
      </div>
    </div>
  );
};

const PersistentNotification = ({ type, onClick = null }) => {
  return (
    <div
      className={`persistent-notification ${
        type === "request_notification" ? "request-notification" : ""
      }`}
    >
      <div className="persistent-notification-title">
        <p>
          Allow us to send you notifications,
          <br />
          that will serve as wellbeing
        </p>
        <h1>Reminders</h1>
      </div>

      <img src="/assets/images/polar_bear.svg" alt="Polar bear" />

      <p>
        People who enable notifications are{" "}
        <span className="emphasize">5 times</span> more likely to keep up the
        healthy micro-habbits!
      </p>

      <CustomButton variant="primary" className="w-75 m-auto" onClick={onClick}>
        Allow
      </CustomButton>
    </div>
  );
};

export { Notifications, FloatingNotification };
