import { UpdateFCMToken } from "./API/auth";
import { getUser } from "./API/user";
import { setCookie } from "./cookie";
//import { getCookie } from "./cookie";

const osConfig = {
  osAppId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
  is_local: process.env.REACT_APP_LOCAL === "true" ? true : false,
};

export default async function runOneSignal() {
  // unregister firebase service worker
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        if (registration.active.scriptURL.includes("firebase")) {
          registration.unregister();
        }
      }
    });
  }

  window.OneSignalDeferred = window.OneSignalDeferred || [];

  window.OneSignalDeferred.push(async function (OneSignal) {
    // initializiam OneSignal
    await OneSignal.init({
      appId: osConfig.osAppId,
      allowLocalhostAsSecureOrigin: osConfig.is_local,
    });

    // utilizatorul deja conectat ?
    let user = getUser();

    if (OneSignal.Notifications.permission) {
      if (user) {
        OneSignal.login(user.id);
        UpdateFCMToken(OneSignal.User.PushSubscription._id);
      }
    }

    // nu avem permisiune browser
    if (OneSignal.Notifications.permission === false) {
      setCookie("attempt_request_notification", "true", 1);
    }

    // event listener pentru cand se schimba sub id
    OneSignal.User.PushSubscription.addEventListener("change", function (e) {
      if (e.current.token) {
        OneSignal.login(user.id);
        UpdateFCMToken(OneSignal.User.PushSubscription._id);
      }
    });

    // Daca e in aplicatie si primeste o notificare, va aparea si in aplicatie
    OneSignal.Notifications.addEventListener(
      "foregroundWillDisplay",
      function (event) {
        let notifications = localStorage.getItem("BTK-notifications");

        if (notifications) {
          notifications = JSON.parse(notifications);
        } else {
          notifications = [];
        }

        notifications.push({
          text: event.notification.body,
          id: Date.now(),
          timeStart: 10,
        });

        localStorage.setItem(
          "BTK-notifications",
          JSON.stringify(notifications)
        );
      }
    );
  });
}
