import { setNotification } from "../Notifications";
import axios from "axios";
import { deleteCookie, getCookie, setCookie } from "../cookie";
import OneSignal from "react-onesignal";
async function handleRequestCode(email) {
  // post a request to the user/auth path with the email in the body using axios fetch
  let response = 0;
  await axios
    .post(process.env.REACT_APP_API_URL + "/user/auth", { email: email })
    .then((res) => {
      // determine office locations
      let officeLocations = false;

      if (res.data.officeLocations && res.data.officeLocations.length > 0) {
        officeLocations = res.data.officeLocations.map((location) => {
          return {
            id: location._id,
            name: location.name,
          };
        });
      }
      response = {
        code: 200,
        type: res.data.type,
        officeLocations: officeLocations,
      };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function testEmailExists(email) {
  // post a request to the user/auth path with the email in the body using axios fetch
  let response = 0;
  await axios
    .post(process.env.REACT_APP_API_URL + "/user/verify-email", {
      email: email,
    })
    .then((res) => {
      // determine office locations

      response = {
        code: 200,
        registered: res.data.registered,
      };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: 400,
          message: JSON.parse(err.request.response).message,
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function handleRequestRegister(token, email, userData) {
  // post a request to the user/auth path with the email in the body using axios fetch
  let response = 0;
  let body = {
    name: userData.name,
    email: email,
    key: token,
    surname: userData.surname,
    officeLocation: userData.location,
    timeOffset: new Date().getTimezoneOffset(),
  };
  await axios
    .post(process.env.REACT_APP_API_URL + "/user/register", body)
    .then((res) => {
      let user = res.data.user;
      let jwtToken = res.data.token;

      localStorage.setItem(
        "BTK-user",
        JSON.stringify({
          name: user.name,
          surname: user.surname,
          id: user._id,
          allowNotifications: user.allowNotifications,
          location: user.officeLocation._id,
          locationName: user.officeLocation.name,
          timeOffset: user.timeOffset,
          email: user.email,
        })
      );

      // set the jwt token cookie
      setCookie(process.env.REACT_APP_COOKIE_OBFUSCATE, jwtToken, 30);

      if (OneSignal.Notifications.permission) {
        OneSignal.login(user._id);
        if (OneSignal.User.PushSubscription.id) {
          UpdateFCMToken(OneSignal.User.PushSubscription.id);
        }
      } else {
        setCookie("attempt_request_notification", "true", 1);
      }
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = JSON.parse(err.request.response);
      else if (err.response && err.response.status === 500)
        response = {
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function handleRequestLogin(token, email) {
  let response = 0;
  await axios
    .post(process.env.REACT_APP_API_URL + "/user/login", {
      email: email,
      key: token,
    })
    .then((res) => {
      let user = res.data.user;
      let jwtToken = res.data.token;

      localStorage.setItem(
        "BTK-user",
        JSON.stringify({
          name: user.name,
          surname: user.surname,
          id: user._id,
          allowNotifications: user.allowNotifications,
          location: user.officeLocation._id,
          locationName: user.officeLocation.name,
          timeOffset: user.timeOffset,
          email: user.email,
        })
      );

      // set the jwt token cookie
      setCookie(process.env.REACT_APP_COOKIE_OBFUSCATE, jwtToken, 30);

      if (OneSignal.Notifications.permission) {
        OneSignal.login(user._id);
        if (OneSignal.User.PushSubscription.id) {
          UpdateFCMToken(OneSignal.User.PushSubscription.id);
        }
      } else {
        setCookie("attempt_request_notification", "true", 1);
      }
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = JSON.parse(err.request.response);
      else if (err.response && err.response.status === 500)
        response = {
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function RemoveAccount() {
  let response = 0;
  await axios
    .delete(process.env.REACT_APP_API_URL + "/user", {
      headers: {
        token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
      },
    })
    .then((res) => {
      Object.keys(localStorage)
        .filter((x) => x.startsWith("BTK-"))
        .forEach((x) => localStorage.removeItem(x));
      deleteCookie(process.env.REACT_APP_COOKIE_OBFUSCATE);
      deleteCookie("notifications_blocked");
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = JSON.parse(err.request.response);
      else if (err.response && err.response.status === 500)
        response = {
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

async function UpdateFCMToken(token) {
  let response = 0;
  await axios
    .post(
      process.env.REACT_APP_API_URL + "/user/FCM",
      {
        token: token,
      },
      {
        headers: {
          token: getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE),
        },
      }
    )
    .then((res) => {
      response = {
        code: 200,
        message: res.data,
      };
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        response = { code: -2 };
      } else if (err.response && err.response.status === 400)
        response = {
          code: err.response.status,
          message: JSON.parse(err.request.response),
        };
      else if (err.response && err.response.status === 500)
        response = {
          code: 500,
          message: "We've encountered a server error. Please try again later.",
        };
      else {
        response = {
          code: -1,
          message: "We've encountered an error. Please try again later.",
        };
      }
    });

  return response;
}

function LogOut() {
  setNotification("Logged out. See you next time!");
  Object.keys(localStorage)
    .filter((x) => x.startsWith("BTK-"))
    .forEach((x) => localStorage.removeItem(x));

  deleteCookie(process.env.REACT_APP_COOKIE_OBFUSCATE);
  deleteCookie("notifications_blocked");
  deleteCookie("attempt_request_notification");

  OneSignal.logout();
}

function isLoggedIn() {
  return getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE) !== null;
}

export {
  isLoggedIn,
  LogOut,
  RemoveAccount,
  handleRequestCode,
  handleRequestRegister,
  handleRequestLogin,
  UpdateFCMToken,
  testEmailExists,
};
